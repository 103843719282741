import React from 'react'
import SbEditable from 'storyblok-react'
import { Grid as MuiGrid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'

const useStyles = makeStyles({
  root: {
    width: (props) => props.width || 'auto',
    height: (props) => props.height || 'auto',
  },
})

const formatProps = (props) => {
  var newProps = props
  const keys = ['xl', 'lg', 'md', 'sm', 'xs']
  const values = {
    false: false,
    true: true,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
  }
  let modified = {}
  keys.forEach((key) => {
    modified[key] = props[key] in values ? values[props[key]] : props[key]
  })
  modified.spacing = parseInt(props.spacing, 10)
  return Object.assign(newProps, modified)
}

const Grid = (props) => {
  const newProps = formatProps(props.blok)
  const classes = useStyles(props.blok)
  return (
    <SbEditable content={props.blok}>
      <MuiGrid
        className={classes.root}
        width={props.blok.width}
        height={props.blok.height}
        container
        component="div"
        justifyContent={newProps.justify}
        alignContent={newProps.alignContent}
        alignItems={newProps.alignItems}
        direction={newProps.direction}
        spacing={newProps.spacing}
        wrap={newProps.wrap}
      >
        {props.blok.columns.map((blok) => (
          <MuiGrid
            key={blok._uid}
            // container={!!newProps.justifyItems}
            item
            zeroMinWidth={true}
            justifyContent={newProps.justifyItems || null}
            xl={newProps.xl}
            lg={newProps.lg}
            md={newProps.md}
            sm={newProps.sm}
            xs={newProps.xs}
          >
            {renderBlok(blok)}
          </MuiGrid>
        ))}
      </MuiGrid>
    </SbEditable>
  )
}

export default Grid
